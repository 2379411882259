<div class="container-fluid">
    <div class="block-color h-100">
        <div class="row h-100">
            <div class="col-md-8">
                <div class="company-block">
                    <div class="company-logo">
                        <img class="image-fluid logo" width="300px" height="100px" src="assets/images/logo.jpg" />
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <a class="logout-btn btn btn-sm" (click)="logout()"> <i class="fas fa-power-off"></i> Logout</a>
                <div class="companies">
                    <h3 class="option-heading">Select Your Company</h3>
                    <ul class="list-group">
                        <li (click)="onClick(company)" class="list-group-item" *ngFor="let company of companyList">
                            {{company.companyName}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
        </div>
    </div>
</div>