



<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#0c7da8" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

<router-outlet></router-outlet>

<notifier-container></notifier-container>

